html, body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-size: 1.2rem;
}

p {
  padding: 0.5rem;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.layout__window {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.layout__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(240, 233, 248, 0.4);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.layout__container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.layout__footer {
  background: rgba(240, 233, 248, 0.4);
  backdrop-filter: blur(5px);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.folderContent {
  width: 100%;
  padding: 0 0 0 1rem;
}

.folderContent_firstLevel {
  padding-left: 0;
}

.folderRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.1rem 0;
}

.explorer__icon {
  color: #aaaaaa;
}

.explorer__icon_folder {
  color: #ffe896;
}

.explorer__icon_remove {
  color: #dd4f43;
}

.folderRow__name {
  flex-grow: 1;
  padding: 0 0.5rem;
}

.DraftEditor-root {
  width: 100%;
  padding: 0.5rem;
  background: white;
  flex-grow: 1;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit;
}

.RichEditor-textAlignCenter {
  text-align: center;
}

.RichEditor-textAlignRight {
  text-align: right;
}